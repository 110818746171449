<template>
  <div class="news">
    <div class="personal_top1">当前积分：0</div>
    <div class="cont">
      <el-table
        border
        :data="tableData"
        :header-cell-style="{ background: '#F3F3F3', color: '#666666' }"
      >
        <el-table-column
          align="center"
          prop="date"
          label="时间"
        ></el-table-column>
        <el-table-column align="center" label="积分">
          <template slot-scope="scope">
              <span class="c_F40707">-5200</span>
              <span class="c_28B28B">+4500</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="name"
          label="方式"
        ></el-table-column>
      </el-table>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        :page-size="pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list_top: [
        { name: "全部消息", id: 1 },
        { name: "未读", id: 2 },
        { name: "已读", id: 3 },
      ],
      tableData: [],
      listTopIndex: 1,
      page: 1, //页码
      pageSize: 5, //每页条数
      total: 0,
    };
  },
  created() {},
  mounted() {},
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    listTopClick(obj) {
      this.listTopIndex = obj.id;
      this.page = 1;
    },
  },
};
</script>

<style lang="less" scoped>
.news {
  .cont {
    padding: 30px;
    .el-button {
      color: #ffffff;
      border: none !important;
      &:hover {
        background: inheriti;
      }
    }
    /deep/.el-table {
      color: #333333;
      th {
        font-size: 18px;
      }
      td {
        font-size: 16px;
      }
    }
  }
  .personal_top {
    /deep/.el-badge {
      .el-badge__content.is-fixed {
        top: 25px;
      }
    }
  }
}
</style>